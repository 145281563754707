import { Auth, I18n } from "aws-amplify"
import jwtDecode from "jwt-decode"

const AWS_USER_LOCAL = "user_aws_local"
const CLIENT_USER_LOCAL = "user_local_data"

export const login = async (email, password) => {
  const user = await Auth.signIn(email, password)
  const { signInUserSession: { idToken: { jwtToken } } } = user
  const groups = jwtDecode(jwtToken)["cognito:groups"]
  console.log(jwtDecode(jwtToken))
  if (groups.includes("admins")) {
    localStorage.setItem(AWS_USER_LOCAL, JSON.stringify(user))
    return user;
  } else {
    throw new Error("Este usuario no tiene acceso a los recursos.");
  }
}

export const logout = () => {
  return new Promise((resolve, reject) => {
    Auth.signOut().then(res => {
      removeLocalData()
      resolve(true)
    }).catch(err => {
      console.log("LOGOUT ERROR: ", err)
      reject(I18n.get(err.message))
    })
  })
}


/**
 * Restore user password
 * @author @leonard_lib
 * @date 29/07/2020
 * @param email
 * @param code
 * @param password
 * @returns {Promise<unknown>}
 */
export const restorePassword = (email, code, password) => {
  return new Promise((resolve, reject) => {
    Auth.forgotPasswordSubmit(
      email,
      code,
      password
    ).then(data => {
      resolve(true)
    }).catch(err => {
      console.log("RESTORE PASSWORD ERROR: ", err)
      reject(I18n.get(err.message))
    })
  })
}

/**
 * Get user data from local or from cognito
 * @author @leonard_lib
 * @date 29/07/2020
 * @param local
 * @returns {Promise<unknown>}
 */
export const getAuthenticatedUser = (local = true) => {
  if (local) {
    const data = localStorage.getItem(AWS_USER_LOCAL)

    if (data != null) {
      return JSON.parse(data)
    }

    return null
  } else {
    return new Promise((resolve, reject) => {
      Auth.currentAuthenticatedUser().then(user => {
        resolve(user)
      }).catch(err => {
        console.log("GET CURRENT USER ERROR: ", err)
        reject(I18n.get(err.message))
      })
    })
  }
}

export const userIsAuthenticated = () => {
  const data = localStorage.getItem(AWS_USER_LOCAL)
  return data != null
}

/**
 * Set new password for AWS console users
 * @author @leonard_lib
 * @date 29/07/2020
 * @param user
 * @param password
 * @returns {Promise<unknown>}
 */
export const completeNewPassword = (user, password) => {
  return new Promise((resolve, reject) => {
    Auth.completeNewPassword(
      user,
      password,
      {}
    ).then(user_two => {
      resolve(user_two)
    }).catch(err => {
      console.log("NEW PASS USER ERROR: ", err)
      reject(I18n.get(err.message))
    })
  })
}


export const removeLocalData = () => {
  localStorage.removeItem(AWS_USER_LOCAL)
  localStorage.removeItem(CLIENT_USER_LOCAL)
}

